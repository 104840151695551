import { Theme, ThemeContext } from '@emotion/react';
import styled, { CSSObject } from '@emotion/styled';
import css, {
  get,
  ResponsiveStyleValue,
  SystemStyleObject,
} from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import StyledSystem from 'styled-system';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  position,
  PositionProps,
} from 'styled-system';

type fontWeightType = keyof Theme['fontWeights'] | CSSObject['fontWeight'];

declare module 'styled-system' {
  interface TypographyProps {
    fontWeight?: fontWeightType | fontWeightType[];
  }
}

const sx = (props) => css(props.sx)(props.theme);
const base = (props) => css(props.__css)(props.theme);
const variant = ({ theme, variant, tx = 'variants' }) =>
  css(get(theme, tx + '.' + variant, get(theme, variant)))(theme);

export type SxStyleProp =
  | SystemStyleObject
  | Record<
      string,
      | SystemStyleObject
      | ResponsiveStyleValue<number | string>
      | Record<
          string,
          SystemStyleObject | ResponsiveStyleValue<number | string>
        >
    >;

export interface BaseProps extends React.RefAttributes<any> {
  as?: React.ElementType;
}

export interface SxProps {
  /**
   * The sx prop lets you style elements inline, using values from your theme.
   */
  sx?: SxStyleProp;
}

// interface AsReactElement {
//   as?: React.ElementType;
// }

// interface AsReduxFirstLink {
//   as: ReduxFirstLink;
//   to?: Record<string, any>;
// }

// interface AsReachLink {
//   as: ReachLink<any>;
//   to?: string;
// }

// interface AsButton {
//   as: 'button';
//   type: 'button' | 'submit' | 'reset';
//   // type: string;
//   alt?: string;
// }

// interface AsAref {
//   as: 'a';
//   href: string;
//   target?: string;
//   rel?: string;
// }

// interface AsForm {
//   as: 'form';
//   noValidate?: boolean;
// }

// interface AsLabel {
//   as: 'label';
//   htmlFor?: string;
// }

// export type AsTypes =
//   | AsReactElement
//   | AsButton
//   | AsReduxFirstLink
//   | AsReachLink
//   | AsAref
//   | AsForm
//   | AsLabel;

export interface BoxKnownProps
  extends BaseProps,
    BorderProps,
    ShadowProps,
    ColorProps,
    LayoutProps,
    SpaceProps,
    FlexboxProps,
    TypographyProps,
    PositionProps,
    GridProps,
    SxProps {
  variant?: StyledSystem.ResponsiveValue<string>;
  tx?: string;
  // css?: Interpolation<Theme>,
  // __css?: SystemStyleObject | string;
  __css?: any;
  color?: any; // https://stackoverflow.com/questions/53711454/styled-system-props-typing-with-typescript

  // when using as=""
  to?: Record<string, any> | string;
  // type: 'button' | 'submit' | 'reset';
  // type: any;
  alt?: string;
  href?: string;
  target?: string;
  rel?: string;
  noValidate?: boolean;
  htmlFor?: string;
}

export interface BoxProps
  extends BoxKnownProps,
    Omit<React.HTMLProps<HTMLDivElement>, keyof BoxKnownProps | 'prefix'> {}

delete layout.config['size'];

export const Box = styled('div' as any, {
  shouldForwardProp: (prop) => prop !== 'loading' && shouldForwardProp(prop),
})(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  base,
  variant,
  sx,
  (props) => props['css'],
  compose(
    shadow,
    border,
    space,
    layout,
    typography,
    color,
    flexbox,
    position,
    grid
  )
) as React.ComponentType<BoxProps>;

export type FlexProps = BoxProps;

// export const Flex = styled(Box)<FlexProps>({
//   display: 'flex',
// });

export const Flex = React.forwardRef<HTMLDivElement, BoxProps>(
  function Flex(props, ref) {
    return (
      <Box
        ref={ref as any}
        __css={{
          display: 'flex',
        }}
        {...props}
      />
    );
  }
);

export const Grid = React.forwardRef<HTMLDivElement, BoxProps>(
  function Grid(props, ref) {
    return (
      <Box
        ref={ref as any}
        __css={{
          display: 'grid',
          gridGap: 3,
          gridTemplateColumns: 'minmax(0, 1fr)', // https://css-tricks.com/preventing-a-grid-blowout/
        }}
        {...props}
      />
    );
  }
);

export const Container = React.forwardRef<HTMLDivElement, BoxProps>(
  function Container(props, ref) {
    const theme = React.useContext<Theme>(ThemeContext as any);

    return (
      <Box
        ref={ref as any}
        __css={{
          mx: 'auto',
          width: '100%',
          px: [2, 3],
          // py: [2, 3],
        }}
        maxWidth={theme.maxGridWidth}
        {...props}
      />
    );
  }
);

export const Row = React.forwardRef<HTMLDivElement, BoxProps>(
  function Row(props, ref) {
    return (
      <Box
        ref={ref as any}
        __css={{
          display: 'flex',
          my: -2,
          mx: -2,
          flexWrap: 'wrap',
        }}
        {...props}
      />
    );
  }
);

export const Column = React.forwardRef<HTMLDivElement, BoxProps>(
  function Column(props, ref) {
    return (
      <Box
        ref={ref as any}
        __css={{ py: 2, px: 2, flex: '1 1 auto' }}
        {...props}
      />
    );
  }
);
